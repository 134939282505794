// extracted by mini-css-extract-plugin
export var heroContainer = "Hero-module--heroContainer--c1xEU";
export var videocontainer = "Hero-module--videocontainer--hQQQ2";
export var heroContentContainer = "Hero-module--heroContentContainer--I3AV8";
export var arrow = "Hero-module--arrow--OlM2q";
export var tickerContainer = "Hero-module--tickerContainer--SUFje";
export var mentorImage = "Hero-module--mentorImage--mqCrL";
export var tickerOne = "Hero-module--tickerOne--aYiMT";
export var tickerTwo = "Hero-module--tickerTwo--tZJRb";
export var tickerKf = "Hero-module--ticker-kf--4dpEX";
export var tickerKfReverse = "Hero-module--ticker-kf-reverse--sMfvZ";
export var ctaContainer = "Hero-module--ctaContainer--5cKWq";
export var joinButton = "Hero-module--joinButton--rybOc";
export var learnMore = "Hero-module--learnMore--xUIUX";
export var rounded = "Hero-module--rounded--aL+eS";