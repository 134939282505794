import * as React from "react";
import { Link } from "gatsby";

import {
  regContainer,
  conqLogo,
  regText,
  regHeading,
  regSubheading,
  regArrow,
  regContent,
} from "./RegBanner.module.css";

import RegLogo from "../../images/RegLogo.png";
import regArrowImg from "../../images/regArrowImg.png";

const RegBanner = () => {
  return (
    <a className={regContainer} href="https://register.conquest.org.in/" rel="noreferrer">
      <img alt="" className={conqLogo} src={RegLogo} />
      <div className={regContent}>
        <div className={regText}>
          <p className={regHeading}>register today!</p>
          <p className={regSubheading}>
          Begin your journey towards making a mark in the entrepreneurial
            world. Register today!
          </p>
        </div>
        <img alt="" className={regArrow} src={regArrowImg} />
      </div>
    </a>
  );
};

export default RegBanner;
