import * as React from "react";
import { Link } from "gatsby";
import { 
    statsContainer,
    stat,
    statNumber,
    statText,
    statRow
 } from './Stats.module.css'


const Stats = () => {
    return(
        <div className={statsContainer}>
            <div className={statRow}>
                <div className={stat}>
                    <Link to = "/sponsors-and-partners">
                    <p className={statNumber}>75+</p>
                    <p className={statText}>investors</p>
                    </Link>
                </div>

                <div className={stat}>
                    <p className={statNumber}>₹30L+</p>
                    <p className={statText}>prize money</p>
                </div>
            </div>

            <div className={statRow}>
                <div className={stat}>
                    <Link to = "/mentors">
                    <p className={statNumber}>250</p>
                    <p className={statText}>mentors</p>
                    </Link>
                </div>

                <div className={stat}>
                    <Link to = "/resource-partners">
                    <p className={statNumber}>$100k+</p>
                    <p className={statText}>resources</p>
                    </Link>
                </div>
            </div>

        </div>
    )
}

export default Stats;