// extracted by mini-css-extract-plugin
export var workContainer = "WhoWeWorkWith-module--workContainer--sfwgI";
export var work1 = "WhoWeWorkWith-module--work1--u6BG-";
export var work2 = "WhoWeWorkWith-module--work2--rvjbX";
export var hashtag = "WhoWeWorkWith-module--hashtag--7TkYX";
export var workText = "WhoWeWorkWith-module--workText--pcJcj";
export var ort = "WhoWeWorkWith-module--ort--jpVo9";
export var workLogos = "WhoWeWorkWith-module--workLogos--gyFCf";
export var workLogoRow = "WhoWeWorkWith-module--workLogoRow--tfwrC";
export var workLogo = "WhoWeWorkWith-module--workLogo--p-xBh";
export var workCard = "WhoWeWorkWith-module--workCard--X6U4b";
export var workcardmt = "WhoWeWorkWith-module--workcardmt--jxJ7o";
export var iconBox = "WhoWeWorkWith-module--iconBox--1Ax26";
export var iconBoxW = "WhoWeWorkWith-module--iconBoxW--vZuU6";
export var workCardTitle = "WhoWeWorkWith-module--workCardTitle--PQO1A";
export var workCardDesc = "WhoWeWorkWith-module--workCardDesc--FYkYL";
export var alumniLink = "WhoWeWorkWith-module--alumniLink--FV8bK";
export var arrow = "WhoWeWorkWith-module--arrow--0pRZS";