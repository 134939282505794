import React from "react";
import { heading, hashtag } from "./Heading.module.css";

export default function (props) {
  return (
    <div style={props.style}>
      <p className={hashtag}>{props.hashtag}</p>
      <h1 style={props.headingStyle} className={heading}>
        {props.start} <span> {props.middle}</span> {props.end}
      </h1>
    </div>
  );
}
