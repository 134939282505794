import * as React from "react";

import {
  heroContainer,
  tickerContainer,
  heroContentContainer,
  joinButton,
  mentorImage,
  tickerOne,
  tickerTwo,
  ctaContainer,
  subHead,
  learnMore,
  videocontainer,
  arrow,
} from "./Hero.module.css";


import videoThumbnail from './/launch-video-thumbnail.jpg';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { Link } from "gatsby";
import img1 from "../../images/heroMentors/Ankur.jpeg";
import img2 from "../../images/heroMentors/kunal.jpeg";
import img3 from "../../images/heroMentors/sanjeev.jpeg";
import img4 from "../../images/heroMentors/sanjay.jpeg";
import img5 from "../../images/heroMentors/rajivSri.jpeg";
import img6 from "../../images/heroMentors/rajiv.jpeg";
import img7 from "../../images/heroMentors/shraddha.jpeg";
import img8 from "../../images/heroMentors/shuvi.jpeg";
import img9 from "../../images/heroMentors/nachiketas.jpeg";
import img10 from "../../images/heroMentors/rajesh.jpeg";
import img11 from "../../images/heroMentors/aviral.jpeg";

const Hero = () => {
  return (
    <div className={heroContainer}>
      <div className={heroContentContainer}>
        <h1>
        <span>BITS Pilani's </span> {'\n'}<br></br> Startup Accelerator<br></br> Is Back </h1>
      </div>
      <div className={tickerContainer}>
      <video className={videocontainer} width="640" height="360" controls poster={videoThumbnail}>
      <source src="../../images/DemoDayRecap.mp4" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
      {/* <iframe modestbranding = "1" width="711" height="400" src="https://www.youtube.com/embed/7YSkrMD-AM8" title="Conquest '23 Launch Video"
       frameborder="0" allow="accelerometer; modestbranding; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen /> */}
      {/* <iframe src='https://www.youtube.com/embed/E7wJTI-1dvQ'
        frameborder='0'
        allow='autoplay; encrypted-media'
        allowfullscreen
        title='video'
/> */}

        {/* <div className={tickerOne}>
          <img alt="" className={mentorImage} src={img5} />
          <img alt="" className={mentorImage} src={img4} />
          <img alt="" className={mentorImage} src={img3} />
          <img alt="" className={mentorImage} src={img2} />
          <img alt="" className={mentorImage} src={img1} />
          <img alt="" className={mentorImage} src={img6} />
          <img alt="" className={mentorImage} src={img5} />
          <img alt="" className={mentorImage} src={img4} />
          <img alt="" className={mentorImage} src={img3} />
          <img alt="" className={mentorImage} src={img2} />
          <img alt="" className={mentorImage} src={img1} />
          <img alt="" className={mentorImage} src={img6} />
        </div>
        <div className={tickerTwo}>
          <img alt="" className={mentorImage} src={img10} />
          <img alt="" className={mentorImage} src={img11} />
          <img alt="" className={mentorImage} src={img7} />
          <img alt="" className={mentorImage} src={img9} />
          <img alt="" className={mentorImage} src={img8} />
          <img alt="" className={mentorImage} src={img10} />
          <img alt="" className={mentorImage} src={img7} />
          <img alt="" className={mentorImage} src={img9} />
          <img alt="" className={mentorImage} src={img8} />
          <img alt="" className={mentorImage} src={img11} />
        </div> */}
      </div>
    </div>
  );
};

export default Hero;
