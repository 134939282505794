import React from "react";
import { hashtag } from "../Mentors/Mentors.module.css";
import {
  mediaContainer,
  mediaHeading,
  newsCardContainer,
  firstNews,
  headerStrip,
  newsSliderParent,
} from "./Media.module.css";

import { indicatorParent, indicator } from "../DemoDay/DemoDay.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";

import { mentorLink, arrow } from "../Mentors/Mentors.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NewsCard from "../../components/NewsCard/NewsCard";
import Heading from "../../components/Heading/Heading";
import ProfileCard from "../../components/ProfileCard/ProfileCard";

export default function Media() {
  const [demo, setDemo] = React.useState();
  const [ref, setRef] = React.useState();
  const [right, setRight] = React.useState(1);
  const [left, setLeft] = React.useState(1);

  const getRef = () => {
    fetch("https://conquest.prismic.io/api/v2/", {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            getData(res.data.refs[0].ref);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };
  const getData = (key) => {
    fetch(
      "https://conquest.prismic.io/api/v2/documents/search?ref=" +
        key +
        "&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ",
      {
        method: "GET",
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            setDemo(res.data.results);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  React.useEffect(() => {
    getRef();
  }, []);

  const slideRight = () => {
    // for non-smooth scroll
    // document.getElementById('container').scrollLeft += 150;

    // for smoother scroll
    var container = document.getElementById("mediacontainer");
    console.log(container);
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      container.scrollLeft += 1200;
      scrollAmount += 1200;
      if (scrollAmount >= 1200) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  };

  const slideLeft = () => {
    // for non-smooth scroll
    // document.getElementById('container').scrollLeft -= 150;

    // for smoother scroll
    var container = document.getElementById("mediacontainer");
    console.log(container);
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      container.scrollLeft -= 1200;
      scrollAmount += 1200;
      if (scrollAmount >= 1200) {
        window.clearInterval(slideTimer);
      }
    }, 25);
  };

  return (
    <div className={mediaContainer}>
      <div className={headerStrip}>
        <div>
          <Heading hashtag="#Trending" start="in the" middle="news" end="" />
        </div>
        <div className={indicatorParent}>
          <button className={indicator} onClick={slideLeft}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button className={indicator} onClick={slideRight}>
            <FontAwesomeIcon icon={faArrowRight} />
          </button>
        </div>
      </div>

      <div className={newsSliderParent}>
        <div className={newsCardContainer} id="mediacontainer">
          {demo ? (
            <>
              {/* {JSON.stringify(demo)} */}
              {demo.map((result, id) => (
                <>
                  {result.type === "media" ? (
                    <>
                      {result.data.newscard.slice(9).map((sp, id) => (
                        <NewsCard
                          className={firstNews}
                          thumbnail={sp.thumbnail.url}
                          readingTime={sp.readingtime}
                          heading={sp.heading[0].text}
                          date={sp.date}
                          to={sp.to.url}
                          logo={sp.logo.url}
                        />
                      ))}
                    </>
                  ) : null}
                </>
              ))}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
