import * as React from "react";
import {
  workContainer,
  work1,
  hashtag,
  workText,
  ort,
  workLogoRow,
  workLogo,
  work2,
  workCard,
  iconBox,
  workLogos,
  workCardTitle,
  workCardDesc,
  alumniLink,
  arrow,
  workcardmt,
  iconBoxW,
} from "./WhoWeWorkWith.module.css";

const WhoWeWorkWith = () => {
  return (
    <div className={workContainer}>
      <div className={work1}>
        {/* <p className={hashtag}>#Entrepreneurship</p> */}
        <p className={workText}>who we</p>
        <p className={workText}>
          <span class={ort}>work </span>
          <span>with</span>
        </p>
        <div className={workLogos}>
          <div className={workLogoRow}>
            <img
              alt=""
              src="https://i.imgur.com/fjU3lqy.png"
              className={workLogo}
            />

            <img
              alt=""
              src="https://i.imgur.com/Gomf3x9.png"
              className={workLogo}
            />
            <img
              alt=""
              src="https://i.imgur.com/5BAiptC.png"
              className={workLogo}
            />
          </div>
          <div className={workLogoRow}>
          <img
              alt=""
              src="https://i.imgur.com/aQN2IIy.png"
              className={workLogo}
            />
            <img
              alt=""
              src="https://i.imgur.com/ELl4pEY.png"
              className={workLogo}
            />
           
            <img
              alt=""
              src="https://i.imgur.com/4kKJ2OT.png"
              className={workLogo}
            />
          </div>
        </div>
      </div>

      <div className={work2}>
        <div className={workcardmt}>
          <div className={iconBoxW}>
            <img
              alt=""
              src="https://i.imgur.com/SSow9vR.png"
              className={iconBox}
            />
          </div>
          <div className={workLogos}>
            <p className={workCardTitle}>Ideation/Validation Stage</p>
            <p className={workCardDesc}> 
              If you have a prototype ready, Conquest helps you get the needed
              headstart to optimize it and validate potential demand
            </p>
          </div>
        </div>

        <div className={workCard}>
          <div className={iconBoxW}>
            <img
              alt=""
              src="https://i.imgur.com/bTrVNsc.png"
              className={iconBox}
            />
          </div>
          <div className={workLogos}>
            <p className={workCardTitle}>Early Traction Stage</p>
            <p className={workCardDesc}>
            This stage comes when the startup has launched its products or services in the market.
              Conquest opens a gateway for startups at a stage where they have launched
              and seeking to grow and sclae rapidly.
            </p>
          </div>
        </div>

        <a href="alumni" target="_blank">
          <div className={alumniLink}>
            <p>Meet our Alumni</p>
            <img
              alt=""
              src="https://i.imgur.com/EoX69EP.png"
              className={arrow}
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export default WhoWeWorkWith;
