import * as React from "react";

import {
  itemContainer,
  itemCH,
  number,
  heading,
  desc,
  line,
  date,
} from "./TimelineItem.module.css";

const TimelineItem = (props) => {
  return (
    <div className={itemContainer}>
      <p className={date}>{props.date}</p>
      <div className={line} />
      <div className={itemCH}>
        <p className={number}>{props.no}</p>
        <p className={heading}>{props.heading}</p>
      </div>
      <p className={desc}>{props.desc}</p>
    </div>
  );
};

export default TimelineItem;
