import React from "react";
import {
  newsCard,
  newsThumbnail,
  newsInfoContainer,
  newsInfo,
  newsLogo,
  date
} from "./NewsCard.module.css";
import { Link } from "gatsby";

export default function NewsCard(props) {
  return (
    <Link className={newsCard} href={props.to}>
      <img className={newsThumbnail} src={props.thumbnail} alt="" />
      <div className={newsInfoContainer}>
        <div className={newsInfo}>
          <p>{props.readingTime} MIN READ</p>
          <h2>{props.heading}</h2>
          <p className={date}>{props.date}</p>
        </div>
        <img className={newsLogo} src={props.logo} alt="" />
      </div>
    </Link>
  );
}
