import * as React from "react";

import {
  invParContainer,
  invParHeading,
  invParGrid,
  invParLogo,
} from "./InvestorPartners.module.css";

import img1 from "../../images/01.png";
import img2 from "../../images/02.png";
import img3 from "../../images/03.png";
import Heading from "../../components/Heading/Heading";

const InvestorsPartners = () => {
  const [ip, setIP] = React.useState();
  const [ref, setRef] = React.useState();

  const getRef = () => {
    fetch("https://conquest.prismic.io/api/v2/", {
      method: "GET",
    }).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            getData(res.data.refs[0].ref);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };
  const getData = (key) => {
    fetch(
      "https://conquest.prismic.io/api/v2/documents/search?ref=" +
        key +
        "&access_token=MTY0NTI2NjYzMDcxOC5ZZ3ZQaVJFQUFDSUFSR05x.77-977-977-977-977-977-9CA9f77-9KO-_vULvv73vv70dEmvvv70J77-9LQok77-977-977-977-977-977-977-977-9&state=m4RTEd4rrZ",
      {
        method: "GET",
      }
    ).then((response) =>
      response
        .json()
        .then((data) => ({
          data: data,
          status: response.status,
        }))
        .then((res) => {
          if (res.data) {
            setIP(res.data.results);
          } else {
            alert("Authentication failed. Please double check your password.");
          }
        })
    );
  };

  React.useEffect(() => {
    getRef();
  }, []);

  return (
    <div className={invParContainer}>
      <Heading start="meet our" middle="investors" />
      <div className={invParGrid}>
        {ip ? (
          <>
            {ip.map((result, id) => (
              <>
                {result.type === "investor_partner" ? (
                  <>
                    {result.data.item.map((sp, id) => (
                      <>
                        {sp.logo.url ? (
                          <>
                            <a href={sp.link.url} target="_blank">
                              <img
                                alt=""
                                className={invParLogo}
                                src={sp.logo.url}
                              />
                            </a>
                          </>
                        ) : null}
                      </>
                    ))}
                  </>
                ) : null}
              </>
            ))}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default InvestorsPartners;
