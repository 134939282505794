import * as React from "react";
import {
  videoContainer,
  videoText,
  videoWhat,
  videoConquest,
  videoDesc,
  videoPlay,
  playImg,
} from "./Video.module.css";

const Video = () => {
  return (
    <div className={videoContainer}>
      <div className={videoText}>
        <p className={videoWhat}>what is</p>
        <p className={videoConquest}>conquest?</p>
        <p className={videoDesc}>
          Conquest, an initiative by the Center for Entrepreneurial Leadership,
          BITS Pilani, is India’s First Student-Run Startup Accelerator. We help
          the best founders build great businesses and raise funding.
        </p>
      </div>
      <a
        href="https://www.youtube.com/watch?v=If02uBA-KGE"
        target="_blank"
        rel="noreferrer"
      >
        <div className={videoPlay}>
          <img
            alt=""
            className={playImg}
            src="https://i.imgur.com/ZFPUx6a.png"
          />
        </div>
      </a>
    </div>
  );
};

export default Video;
